import {
  StoreName,
  withError,
  withLoaded,
  withLoading,
} from 'src/app/core/store/store.config';
import { UIEntity } from 'src/app/shared/models/entity.model';

import { createStore } from '@ngneat/elf';
import {
  withActiveId,
  withEntities,
  withUIEntities,
} from '@ngneat/elf-entities';

import { Site } from './site.model';

export const siteStore = createStore(
  { name: StoreName.Site },
  withEntities<Site, 'uuid'>({ idKey: 'uuid' }),
  withUIEntities<UIEntity, 'uuid'>({ idKey: 'uuid' }),
  withActiveId(),
  withLoading(),
  withLoaded(),
  withError(),
);
